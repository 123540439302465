'use client';

import { B2BLoginForm } from '@components/auth/B2bLoginForm';
import { useAuth } from '@lib/b2b/authContext';
import { useAppContext } from '@lib/context/appContext';
import { getPathFromMarket } from '@lib/utils/clUtils';
import { B2BUser } from '@model/auth';
import { isB2BEnabled } from '@utils/config';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import config from '../../data/config.json';

export default function Home() {
  const router = useRouter();
  const { state } = useAppContext();
  const [error, setError] = useState<string>('');
  const [calledPush, setCalledPush] = useState(false);

  const useAuthParams = isB2BEnabled ? useAuth() : null;
  const user = !!useAuthParams ? useAuthParams.user : null;
  const login = !!useAuthParams ? useAuthParams.login : null;

  function detectLocale(defaultLocale: string, locales: string[]) {
    const language = config.configuration.locale.code || 'it';

    const exists = locales.includes(language);

    if (exists) {
      return language;
    }
    return defaultLocale;
  }

  useEffect(() => {
    if (!isB2BEnabled && state?.marketId) {
      const { locale: defaultLocale, locales, marketId } = state;
      const locale = detectLocale(defaultLocale, locales);
      const redirectUrl = getPathFromMarket(marketId, locale);
      const search = window.location.search;
      const url = `${redirectUrl}${search ? search : ''}`;
      if (calledPush) {
        return; // no need to call router.push() again
      }
      router.push(url);
      setCalledPush(true);
    }
  }, [state]);

  const redirect = (email: string | null) => {
    if (isB2BEnabled && state?.marketId) {
      const { locale: defaultLocale, locales, marketId } = state;
      const locale = detectLocale(defaultLocale, locales);
      let redirectUrl = getPathFromMarket(marketId, locale);
      const search = window.location.search;
      if (!!email) {
        redirectUrl += `${search ? search + '&utm_source=' + email : '?utm_source=' + email}`;
      }
      if (redirectUrl) {
        router.push(redirectUrl);
      }
    }
  };

  const onSubmit = async (data: { email: string; password: string }) => {
    if (isB2BEnabled) {
      setError('');
      try {
        const result = await login!(data);
        if (!result) {
          setError('Login failed');
        }
      } catch (error: unknown) {
        if (error instanceof TypeError) {
          setError(error.message);
        }
      }
    }
  };

  useEffect(() => {
    if (isB2BEnabled && user) {
      setTimeout(() => {
        redirect((user! as B2BUser).email);
      }, 0);
    }
  }, [user]);

  return (
    isB2BEnabled && (
      <div
        style={{
          marginTop: 20,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {!!user ? (
          <div>
            <h3>Hello {(user as B2BUser).email}</h3>
          </div>
        ) : (
          <div style={{ maxWidth: 350, padding: 5 }}>
            {error && <div className="form__error">{error}</div>}
            <B2BLoginForm onDone={(data) => onSubmit(data)} />
          </div>
        )}
      </div>
    )
  );
}
